// src/components/GridView.tsx
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { ClipLoader } from 'react-spinners';
import './GridView.css';
import { User, sendEmailVerification } from 'firebase/auth';
import defaultCardImage from '../assets/defaultcard.jpg'; // Importiere das Standardbild
import { toast } from 'react-toastify';
import { FaCar, FaCity, FaRegMoneyBillAlt, FaTools } from 'react-icons/fa';
import welcomeIllustration from '../assets/welcome_illustration.svg'; // Importiere eine einladende Illustration
// import { useFirebaseServicesNew } from '../firebaseConfig'; // Import the hook for Firebase services

interface Property {
  uid: string;
  type: string;
  model: string;
  price: number;
  userUID: string;
  title: string;
  mainImageURL?: string; // Optionales Attribut
  year: number;
  month: number;
  performance: number;
  mileage: number;
  city: string;
}

interface GridViewProps {
  user: User | null;
}

const GridView: React.FC<GridViewProps> = ({ user }) => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Lade Firebase-Dienste, einschließlich Firestore-Datenbank
  // const firebaseServices = useFirebaseServicesNew();

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      if (user) {
          const propertiesCollection = collection(db, 'properties');
          const q = query(propertiesCollection, where('userUID', '==', user.uid));
          const propertiesSnapshot = await getDocs(q);
          const propertiesList = propertiesSnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() } as Property));
          console.log('Properties:', propertiesList);
          setProperties(propertiesList);
        // }
      } else {
        setProperties([]); // Leere den Zustand der Eigenschaften
      }
      setLoading(false);
    };

    fetchProperties();
  }, [user]);

  const handleResendVerificationEmail = async () => {
    if (user) {
      try {
        console.log('Resending verification email...', user, user.email);
        await sendEmailVerification(user);
        toast.success('Verifizierungs-E-Mail erneut gesendet!');
      } catch (error) {
        toast.error('Fehler beim Senden der Verifizierungs-E-Mail.');
      }
    }
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="placeholder-container">
        <div className="placeholder-content">
          <h2>Herzlich Willkommen bei Automatch! Bitte melden Sie sich an, um Ihre Fahrzeuge zu sehen.</h2>
          <p>Sie können sich anmelden, um Ihre Fahrzeuge zu verwalten und neue Fahrzeuge hinzuzufügen.</p>
          <button onClick={handleLoginClick} className="fancy-button">
            Anmelden
          </button>
        </div>
      </div>
    );
  }

  if (!user.emailVerified) {
    return (
      <div className="placeholder-container">
        <div className="placeholder-content">
          <h2>E-Mail-Adresse nicht bestätigt</h2>
          <p>Bitte bestätigen Sie Ihre E-Mail-Adresse, um fortzufahren.</p>
          <button onClick={handleResendVerificationEmail} className="fancy-button">
            E-Mail erneut senden
          </button>
        </div>
      </div>
    );
  }

  if (properties.length === 0) {
    return (
      <div className="placeholder-container">
        <div className="placeholder-content">
          <h2>Keine Fahrzeuge gefunden</h2>
          <p>Es wurden keine Fahrzeuge gefunden. Fügen Sie neue Fahrzeuge hinzu, um sie hier anzuzeigen.</p>
          <button className="fancy-button" onClick={() => navigate('/add-car')}>Fahrzeug hinzufügen</button>
        </div>
      </div>
    );
  }

  return (
    <div className="grid-container">
      <button className="floating-add-car-button" onClick={() => navigate('/add-car')}>+</button>
      {properties.map((property) => (
        <Link to={`/details/${property.uid}`} key={property.uid} className="grid-item">
          <img
            src={property.mainImageURL || defaultCardImage}
            alt={property.title}
            className="grid-item-image"
          />
          <div className="grid-item-content">
            <h2 className="grid-item-title">{property.title}</h2>
            <div className="grid-item-details">
              <div className="grid-item-detail">
                <FaCar className="grid-item-icon" />
                <span className="grid-item-label">Marke:</span>
                <span className="grid-item-value">{property.type}</span>
              </div>
              <div className="grid-item-detail">
                <FaTools className="grid-item-icon" />
                <span className="grid-item-label">Modell:</span>
                <span className="grid-item-value">{property.model}</span>
              </div>
              <div className="grid-item-detail">
                <FaRegMoneyBillAlt className="grid-item-icon" />
                <span className="grid-item-label">Preis:</span>
                <span className="grid-item-value">{property.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</span>
              </div>
              <div className="grid-item-detail">
                <FaCity className="grid-item-icon" />
                <span className="grid-item-label">Ort:</span>
                <span className="grid-item-value">{property.city}</span>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default GridView;
